
import { getBoard } from '@/api/board';
import { Vue, Component } from 'vue-property-decorator';
import BoardSupportList from '@/components/board/support/list.vue';
import moment from 'moment';
import { IPost } from '@/types';
import Pagination from '@/components/board/pagination.vue';
import { UserModule } from '@/store/user';
import { getBusinessList } from '@/api/business';
import { getBoardCategory } from '@/api/boardCategory';

@Component({
  name: 'AppSupportList',
  components: {
    BoardSupportList,
    Pagination,
  },
})
export default class extends Vue {
  mounted() {
    this.init();
  }

  get isLogined() {
    return UserModule.token;
  }

  private listQuery: any = {
    page: 0,
    size: 10,
    searchValue: '',
    sort: 'progress',
    progress: 0,
    categories: [],
    startDate: '',
    endDate: '',
  };

  private category: any = {
    categoryName: '',
    children: [],
  };

  private tmpCategory = 'all';

  private selectedCategoryList: any = [];

  private businessList = [];

  private totalElements = 0;

  private totalPages = 1;

  private async init() {
    await getBoardCategory('33b34a3c-b79c-43bd-8042-e364d0f560da').then((res) => {
      this.category = res.data;
      if (this.$route.params.category !== '') this.tmpCategory = this.$route.params.category;
      res.data.children.forEach((child: any) => this.selectedCategoryList.push(child.uid));
      this.getBusinessList();
    });
  }

  getBusinessList() {
    if (this.tmpCategory === 'all') {
      this.listQuery.categories = this.selectedCategoryList.join(',');
    } else {
      this.listQuery.categories = this.tmpCategory;
    }
    getBusinessList(this.listQuery).then((res: any) => {
      this.businessList = res.data.content;
      this.totalElements = res.data.totalElements;
      this.totalPages = res.data.totalPages;
    });
  }

  private handleSearch() {
    this.listQuery.page = 0;
    this.getBusinessList();
  }

  private handleClickProgress(progress: number) {
    this.listQuery.progress = progress;
    this.listQuery.page = 0;
    this.getBusinessList();
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.getBusinessList();
  }

  private isEnd(dueDate: number) {
    if (dueDate >= 1) return `마감 ${dueDate} 일전`;
    if (dueDate === 0) return '오늘 마감';
    return '마감';
  }

  private isEndClass(business: any) {
    if (!business.dueDate) return 'd-day';
    const today = moment();
    const endDay = moment(business.dueDate, 'YYYY-MM-DD');
    const remain = endDay.diff(today, 'days');
    if (remain >= 30) return 'd-7';
    if (remain >= 7) return 'd-7';
    if (remain >= 0) return 'd-3';
    return 'd-day';
  }
}
